<template>
  <div class="aiz-user-panel">
    
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
            <h1 class="h3">{{ $t('wo-de-qian-bao') }}</h1>
        </div>
      </div>
    </div>

    <div class="row gutters-10">
      <div class="col-md-4 mx-auto mb-3" >
        <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
          <span class="size-30px rounded-circle mx-auto bg-soft-primary d-flex align-items-center justify-content-center mt-3">
            <i class="las la-dollar-sign la-2x text-white"></i>
          </span>
          <div class="px-3 pt-3 pb-3">
            <div class="h4 fw-700 text-center">${{(userMoney)}}</div>
            <div class="opacity-50 text-center">{{ $t('qian-bao-yu-e-0') }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mx-auto mb-3" >
        <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition" @click="openRecharge">
          <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
            <i class="las la-plus la-3x text-white"></i>
          </span>
          <div class="fs-18 text-primary">{{ $t('chong-zhi-qian-bao') }}</div>
        </div>
      </div>
      <!-- <div class="col-md-3 mx-auto mb-3" >
        <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition" @click="openOffline">
          <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
            <i class="las la-plus la-3x text-white"></i>
          </span>
          <div class="fs-18 text-primary">线下充值</div>
        </div>
      </div> -->
      
      
      <div class="col-md-4 mb-3 mx-auto">
        <div class="bg-grad-2 p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition" @click="openWithdraw">
          <span
            class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
            <i class="las la-plus la-3x text-white"></i>
          </span>
          <div class="fs-18 text-white">{{ $t('shen-qing-ti-kuan-0') }}</div>
        </div>
      </div>
    </div>

<!--    推广链接 -->
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('tui-guan-lian-jie') }}</h5>
      </div>
      <div class="card-body">
        <div class="form-box-content p-3">
          <div class="form-group">
            <textarea id="referral_code_url" class="form-control" readonly type="text" >{{ app_promotion }}/#/register?code={{userInfo.InvitationCode}}</textarea>
          </div>
          <button type=button id="ref-cpurl-btn" class="btn btn-primary float-right copy-icon" @click="copyData">{{ $t('fu-zhi-di-zhi') }}</button>
        </div>
      </div>
    </div>

<!--    钱包收支明细 -->
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('qian-bao-shou-zhi-ming-xi') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0" :class="payHistoryList.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th>#</th>
              <th data-breakpoints="lg">{{ $t('ri-qi') }}</th>
              <th>{{ $t('jiao-yi-jin-e') }}</th>
              <th data-breakpoints="lg">{{ $t('fu-kuan-fang-shi') }}</th>
<!--              <th >{{ $t('sheng-yu-jin-e') }}</th>-->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in payHistoryList" :key="i"  v-if="item.Type != 24">
              <td>{{ i + 1 }}</td>
              <td>{{ item.CreatedAt }}</td>
              <td>{{ item.Practical }}</td>
              <td>{{ getTypeName(item.Type) }}</td>
<!--              <td >{{item.DebtAfter}}</td>-->
            </tr>
             <tr class="footable-empty" v-if="payHistoryList.length == 0"><td colspan="8">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination" v-if="debtPage.total > 1">
          <pageBar :totalPage="debtPage.total" :page="debtPage.page" @change="changeDebtPage"></pageBar>
        </div>
      </div>
    </div>

      
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('ti-xian-li-shi-ji-lu') }}</h5>
      </div>
      <div class="card-body">
        <table class="table mb-0" :class="withdrawLogList.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
                <th>#</th>
                <th>{{ $t('ri-qi') }}</th>
                <th>{{ $t('jin-e') }}</th>
                <th>{{ $t('lei-xing') }}</th>
                <th data-breakpoints="lg">{{ $t('zhuang-tai') }}</th>
                <th>{{ $t('ti-xian-fang-shi-0') }}</th>
<!--                <th>{{ $t('bei-zhu') }}</th>-->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in withdrawLogList" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.CreatedAt }}</td>
              <td>{{ parseFloat(item.Price).toFixed(2) }}</td>
              <td>
                  {{item.CoinType == 1 ? $t('User balance') : $t('bao-zheng-jin')}}
              </td>
              <td> 
                <span class=" badge badge-inline badge-success" v-if="item.Status == 1">{{ $t('yi-tong-guo-0') }}</span>
                <span class=" badge badge-inline badge-danger" v-if="item.Status == 2">{{ $t('yi-ju-jue-0') }}</span>
                <span class=" badge badge-inline badge-info" v-if="item.Status == 0">{{ $t('shen-he-zhong') }}</span>
              </td>
              <td>{{item.Type == 'usdt' ? 'USDT' : (item.Type == 'bank' ? $t('yin-hang-ka') : $t('xian-jin'))}}</td>
<!--              <td>{{item.Remark}}</td>-->
            </tr>
             <tr class="footable-empty" v-if="withdrawLogList.length == 0"><td colspan="8">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('chong-zhi-li-shi-ji-lu') }}</h5>
      </div>
      <div class="card-body">
        <table class="table mb-0" :class="rechargeLogList.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
                <th>#</th>
                <th>{{ $t('ri-qi') }}</th>
                <th>{{ $t('jin-e') }}</th>
                <th>{{ $t('chong-zhi-lei-xing') }}</th>
                <th data-breakpoints="lg">{{ $t('zhuang-tai') }}</th>
<!--                <th>{{ $t('bei-zhu') }}</th>-->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in rechargeLogList" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.CreatedAt }}</td>
              <td>{{ parseFloat(item.Amount).toFixed(2) }}</td>
              <td>
                {{item.CoinType == 1 ? $t('User balance') : $t('bao-zheng-jin')}}
              </td>
              <td> 
                <span class=" badge badge-inline badge-success" v-if="item.Status == 1">{{ $t('yi-tong-guo-0') }}</span>
                <span class=" badge badge-inline badge-danger" v-if="item.Status == 2">{{ $t('yi-ju-jue-0') }}</span>
                <span class=" badge badge-inline badge-info" v-if="item.Status == 0">{{ $t('shen-he-zhong') }}</span>
              </td>
<!--              <td>{{item.Remark}}</td>-->
            </tr>
             <tr class="footable-empty" v-if="rechargeLogList.length == 0"><td colspan="6">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination" v-if="rechargePage.total > 1">
          <pageBar :totalPage="rechargePage.total" :page="rechargePage.page" @change="changeRechargePage"></pageBar>
        </div>
      </div>
    </div>

    <Popup v-model="showRechargePopup">
      <div class="popup-box " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('chong-zhi-qian-bao-0') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">
                <div class="row">
                  <div class="col-md-4">
                    <label>{{ $t('jin-e') }}<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8">
                    <input type="number"  class="form-control mb-3" v-model="rechargeForm.money" :placeholder="$t('jin-e')" required>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                      <label>{{ $t('zi-jin-lei-xing') }}  <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8">
                    <div class="mb-3">
                      <a-select class="form-control" v-model="rechargeForm.coinType" size="large" :placeholder="$t('qing-xuan-ze-zi-jin-lei-xing-1')">
                        <a-select-option class="" :value="item.value" v-for="(item, i) in payTypeList" :key="i">{{ item.label }}</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  </div>
                  <div class="form-group text-right">
                    <button type="button" class="btn btn-sm btn-primary transition-3d-hover mr-1" @click="submitRecharge">{{ $t('que-ding') }}</button>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>

    <Popup v-model="showOfflinePopup">
      <div class="popup-box" id="offline_wallet_recharge_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('xian-xia-chong-zhi') }}</h5>
              <button type="button" class="close" data-dismiss="modal"  @click="cancel"></button>
            </div>
            <div id="offline_wallet_recharge_modal_body">
              <form class="" >
                <div class="modal-body gry-bg px-3 pt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label>{{ $t('jin-e') }}<span class="text-danger">*</span></label>
                    </div>
                    <div class="col-md-8">
                      <input type="number" lang="en" class="form-control mb-3" name="amount" :placeholder="$t('jin-e')" required>
                    </div>
                  </div>
                  
                  <div class="form-group text-right">
                    <button type="submit" class="btn btn-sm btn-primary transition-3d-hover mr-1">{{ $t('que-ding') }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Popup>


    <Popup v-model="showWithdrawPopup">
      <div class="popup-box" id="request_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('ti-xian-shen-qing') }}</h5>
              <button type="button" class="close" data-dismiss="modal"  @click="cancel"></button>
            </div>
            <form class="" v-if="userMoney > 5">
              <div class="modal-body gry-bg px-3 pt-3">
                <div class="row">
                  <div class="col">
                    <div class="alert alert-success" role="alert">
                      <h6>{{ $t('nin-de-ke-ti-xian-yu-e') }} ${{(withdrawMoney)}}</h6>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('jin-e') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                    <input type="number"  class="form-control mb-3" v-model="withdrawForm.money" :placeholder="$t('jin-e')" required>
                  </div>
                </div>
                <div class="row" v-show="false">
                  <div class="col-md-3">
                    <label>{{ $t('zi-jin-lei-xing') }}</label>
                  </div>
                  <div class="col-md-9">
                    <a-select class="form-control mb-3" v-model="withdrawForm.coinType" size="large" :placeholder="$t('qing-xuan-ze-zi-jin-lei-xing-1')">
                      <a-select-option class="" :value="item.value" v-for="(item, i) in payTypeList" :key="i">{{ item.label }}</a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-md-3">
                      <label>{{ $t('ti-xian-fang-shi-0') }}</label>
                  </div>
                  <div class="col-md-9">
                    <a-select class="form-control mb-3" v-model="withdrawForm.type" size="large" :placeholder="$t('qing-xuan-ze-zi-jin-lei-xing-1')">
                      <a-select-option class="" :value="item.value" v-for="(item, i) in withdrawTypeList" :key="i">{{ item.label }}</a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('jiao-yi-mi-ma-0') }}</label>
                  </div>
                  <div class="col-md-9">
                      <input type="password"  class="form-control mb-3" v-model="withdrawForm.password" :placeholder="$t('jiao-yi-mi-ma-0')" required>
                  </div>
                </div>
                <div class="form-group text-right">
                    <button type="button" class="btn btn-sm btn-primary" @click="submitWithdraw">{{ $t('ti-jiao') }}</button>
                </div>
              </div>
            </form>
            <div class="modal-body gry-bg px-3 pt-3" v-else>
              <div class="p-5 heading-3">
                {{ $t('nin-mei-you-zu-gou-de-yuelai-ti-kuan') }} </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import {withdrwaLog, debtList, debtTypeList, rechargeLog, doRecharge, doWithdraw, userInfo} from '@/api/user'
import pageBar from '@/components/page'
import {Popup} from 'vant'
import { resetPrice } from '@/utils/common'
import Clipboard from "clipboard";
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      rechargeForm: {
        money: '',
        coinType: '',
      },
      withdrawForm: {
        money: '',
        coinType: undefined,
        type: undefined,
        password: ''
      },
      showRechargePopup: false,
      showOfflinePopup: false,
      showWithdrawPopup: false,
      userMoney: '0.00',
      withdrawMoney: '0.00',
      payHistoryList: [],
      withdrawLogList: [],
      rechargeLogList: [],
      payTypeList: [
        {
          value: '1',
          label: this.$t('zhang-hu-yu-e')
        },
      ],
      withdrawTypeList: [ ],
      page: {
        page: 1,
        limit: 10,
        total: 1
      },
      debtPage: {
        page: 1,
        limit: 10,
        total: 1
      },
      rechargePage: {
        page: 1,
        limit: 10,
        total: 1
      },
      typeNameObj: {}
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    language() {
      return this.$store.state.language
    },
    app_promotion() {
      return this.$store.state.app_promotion
    },
  },
  watch: {
    userInfo(val) {
      if (val.UserAccount) {
        this.userMoney = parseFloat(this.userInfo.UserAccount.Balance).toFixed(2)
        this.withdrawMoney = parseFloat(this.userInfo.UserAccount.Withdraw).toFixed(2)
        this.initWithdrawType()
      }
    },
    language() {
      this.initWithdrawType()
    },
    withdrawType(){
      return this.$store.state.withdraw_type
    }
  },
  mounted() {
    this.initLog()
    this.initRechargeLog()
    this.initDebt()
    this.initType()

    if (this.userInfo.UserAccount) {
      // this.userMoney = parseFloat(this.userInfo.UserAccount.Balance).toFixed(2)
      // this.withdrawMoney = parseFloat(this.userInfo.UserAccount.Withdraw).toFixed(2)
    }
    userInfo().then(res => {
      this.userMoney = parseFloat(res.data.UserAccount.Balance).toFixed(2)
      this.withdrawMoney = parseFloat(res.data.UserAccount.Withdraw).toFixed(2)
      this.initWithdrawType()

    })
  },
  methods: {
    resetPrice,
    initDebt() {
      let form = new FormData()
      form.append('current', this.debtPage.page)
      form.append('pageSize', this.debtPage.limit)
      debtList(form).then(res => {
        this.payHistoryList = res.data.Items
        this.debtPage.total = res.data.Pagination.totalPage
      })
    },
    copyData() {
      let data = `${this.$store.state.app_promotion}/#/register?code=${this.userInfo.InvitationCode}`
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast.fail(this.$t('Copy successful'))
    },
    initWithdrawType() {
      this.withdrawTypeList = []
      if (this.userInfo.IsUsdt && this.$store.state.withdraw_type.indexOf("usdt") > -1) {
        this.withdrawTypeList.push({
          value: 'usdt',
          label: 'USDT'
        })
      }
      if (this.userInfo.IsUstd && this.$store.state.withdraw_type.indexOf("ustd") > -1) {
        this.withdrawTypeList.push({
          value: 'ustd',
          label: 'USTD'
        })
      }
      if (this.userInfo.IsCash) {
        this.withdrawTypeList.push({
          value: 'cash',
          label: this.$t('xian-jin')
        })
      }
      if (this.userInfo.IsBank && this.$store.state.withdraw_type.indexOf("bank") > -1) {
        this.withdrawTypeList.push({
          value: 'bank',
          label: this.$t('yin-hang-ka')
        })
      }
     
    },
    initLog() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      withdrwaLog(form).then(res => {
        this.withdrawLogList = res.data.Items
        this.page.total = res.data.Pagination.totalPage
      })
    },
    initRechargeLog() {
      let form = new FormData()
      form.append('current', this.rechargePage.page)
      form.append('pageSize', this.rechargePage.limit)
      rechargeLog(form).then(res => {
        this.rechargeLogList = res.data.Items
        this.rechargePage.total = res.data.Pagination ? res.data.Pagination.totalPage : 1
      })
    },
    initType() {
      debtTypeList().then(res => {
        this.typeNameObj = res.data
      })
    },
    changePage(page) {
      this.page.page = page
      this.initLog()
    },
    changeDebtPage(page) {
      this.debtPage.page = page
      this.initDebt()
    },
    changeRechargePage(page) {
      this.rechargePage.page = page
      this.initRechargeLog()
    },
    cancel() {
      this.showRechargePopup = false
      this.showOfflinePopup = false
      this.showWithdrawPopup = false
    },
    openRecharge() {
      this.showRechargePopup = true
    },
    openOffline() {
      this.showOfflinePopup = true
    },
    openWithdraw() {
      if (this.withdrawTypeList.length == 0) {
        this.$toast.fail(this.$t('qing-zeng-jia-shou-kuan-fang-shi'))
        setTimeout(() => {
          this.$router.push({
            name: 'userInfo'
          })
        }, 500)
        return
      }
      this.showWithdrawPopup = true
    },
    getTypeName(type) {
      let str = type
      if (this.typeNameObj && this.typeNameObj[type + '']) {
        str = this.typeNameObj[type + '']
      }
      return str
    },
    submitRecharge() {
      if (!this.rechargeForm.money) {
        this.$toast.fail(this.$t('qing-shu-ru-chong-zhi-jin-e'))
        return
      }
      // if (!this.rechargeForm.coinType) {
      //   this.$toast.fail(this.$t('qing-xuan-ze-zi-jin-lei-xing-1'))
      //   return
      // }
      let form = new FormData()
      form.append('Amount', this.rechargeForm.money)
      form.append('CoinType', this.rechargeForm.coinType)
      doRecharge(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('chong-zhi-cheng-gong'))
          this.rechargePage.page = 1
          this.initRechargeLog()
          this.showRechargePopup = false
          this.$router.push({
            name: 'kefu'
          })
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    submitWithdraw() {
      if (!this.withdrawForm.money) {
        this.$toast.fail(this.$t('qing-shu-ru-ti-xian-jin-e'))
        return
      }
      if (!this.withdrawForm.type) {
        this.$toast.fail(this.$t('tixian-desc1'))
        return
      }
       if (!this.withdrawForm.password) {
        this.$toast.fail(this.$t('qing-shu-ru-jiao-yi-mi-ma'))
        return
      }
      let form = new FormData()
      form.append('Price', this.withdrawForm.money)
      // form.append('CoinType', this.withdrawForm.coinType)
      form.append('CoinType', 1)
      form.append('Type', this.withdrawForm.type)
      // form.append('Type', "usdt")
      form.append('SafePassword', this.withdrawForm.password)
      doWithdraw(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('ti-xian-shen-qing-cheng-gong'))
          this.page.page = 1
          this.showWithdrawPopup = false
          this.initLog()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>